import { Link } from "gatsby"
import * as React from "react"
import { useState } from "react"
import tw from "twin.macro"
import { UpArrow } from "../Icons/UpArrow"
import Slash from "../../images/slash.png"
import Stripes from "../../images/slashes-extended.png"
// import Hero from "../../images/mobile-background.jpg"
// import MapAL from "../../images/map_AL.png"
import BG_outdoors from "../../images/home_outdoors.jpg"
import Mobile_BG_outdoors from "../../images/bg-bike-mobile-2.png"
// import Robotics from "../../images/home_robotics.png"
// import Technicians from "../../images/career_resources.jpg"
// import Lifestyle from "../../images/lifestyle-mother-and-son.jpg"
// import AlexT from "../../images/alex_t_home.jpg"

// import Hero from "../../images/one_sheeter_4.jpeg"
import Hero from "../../images/one_sheeter_4_blue_50.png"
import MapAL from "../../images/map_AL.png"
// import BG_outdoors from "../../images/one_sheeter_4.jpeg"
// import Mobile_BG_outdoors from "../../images/bg-bike-mobile-2.png"
import Robotics from "../../images/one_sheeter_5.jpg"
import Technicians from "../../images/one_sheeter_1.jpg"
import Lifestyle from "../../images/one_sheeter_3.jpeg"
import AlexT from "../../images/one_sheeter_2.jpg"

import useAuth from "../../hooks/useAuth"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"

const Home: React.FC<{ data: any; assets: any }> = ({ data, assets }) => {
  const [activeTab, setActiveTab] = useState(1)
  const { isAuthenticated, state } = useAuth()

  React.useEffect(() => {
    fetch(`${process.env.GATSBY_API_ROOT_URL}/auth/whoami`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${state?.token}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("visitorID", data.visitor.visitorID)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])
  return (
    <>
      <section
        css={[tw`bg-gray-100 px-5 pt-0 lg:pt-10 pb-96 lg:pb-0 md:px-0 lg:pt-0`]}
      >
        <div
          css={[
            tw`mx-auto mb-16 pt-10 lg:pt-16 lg:px-96 container flex flex-col justify-center items-center content-center h-[40em] lg:h-[48em] lg:w-full bg-no-repeat bg-top lg:bg-cover`,
            `background-image: url(${Hero})`,
            `background-size: auto 100%`,
          ]}
        >
          <h3 css={[tw`pb-3 lg:pb-8 px-10 text-white text-center`]}>
            {data.headline}
          </h3>
          <div>
            {isAuthenticated ? (
              <Link to={"/find-your-career"}>
                <button
                  className="lg-button"
                  type="button"
                  value="career"
                  onClick={() => {
                    AnalyticsEventsClient.event({
                      eventType: "click",
                      eventName: "click_start_career",
                      eventMessage:
                        "store the button click event Find Your Career",
                      visitorID: localStorage.getItem("visitorID"),
                    })
                  }}
                >
                  {data.button}
                </button>
              </Link>
            ) : (
              <div></div>
              // <Link to="/check-registration">
              //   <button className="lg-button" type="button" value="career">
              //     {data.button}
              //   </button>
              // </Link>
            )}
          </div>
          {/* {!isAuthenticated && <p css={[tw`pt-32 lg:pt-0 pb-8 text-white`]}>{data.CTA_text} {" "}<Link to="/check-registration"><span css={[tw`underline cursor-pointer`]}>
          {data.CTA}
        </span></Link></p>} */}
        </div>
      </section>
      <section
        css={[
          `background-image: url(${BG_outdoors})`,
          tw` hidden lg:block lg:h-full lg:bg-cover bg-no-repeat bg-top lg:pb-32`,
        ]}
      >
        <div css={[tw`mx-auto container grid grid-cols-1 lg:grid-cols-2  `]}>
          <div css={[tw`col-span-1 mx-auto`]}>
            <div
              css={[
                tw`bg-red-300  p-20 ml-0 lg:ml-5 mt-[-32em] lg:mt-[-10em]  md:p-20 relative`,
              ]}
            >
              <p
                css={[tw`text-white`]}
                dangerouslySetInnerHTML={{ __html: data.copy }}
              ></p>
            </div>
            <div
              css={[
                tw`w-48  lg:w-96 left-0 right-0 bottom-[-33px] lg:top-[-70px] mx-auto border-t-red-300 border-t-[35px] lg:border-t-[75px] border-r-transparent border-r-[120px] lg:border-r-[195px] border-l-transparent border-l-[120px] lg:border-l-[195px]`,
              ]}
            ></div>
          </div>
          <div css={[tw`col-span-1 h-full`]}>
            <div
              css={[
                tw`relative h-full flex justify-center lg:justify-start content-end items-end`,
              ]}
            >
              <img
                src={MapAL}
                alt="map of Alabama"
                css={[tw`h-36 lg:h-auto mt-[-2rem] lg:mt-0 lg:ml-[-4rem] `]}
              />
            </div>
          </div>
        </div>
        <div
          css={[
            tw`mx-auto container grid grid-cols-1 xl:grid-cols-8 mt-5 xl:mt-32 mx-auto px-5 lg:gap-10 content-center items-center justify-center`,
          ]}
        >
          <div css={[tw` col-span-1 xl:col-span-2 `]}>
            {/* <div css={[tw`flex items-start justify-center xl:items-end xl:flex-col my-3`]}>
            <div css={[tw`flex flex-col xl:flex-row items-center content-center justify-center xl:justify-end xl:pb-10 hover:scale-105 cursor-pointer`]} onClick={() => setActiveTab(1)}>
              <UpArrow css={activeTab == 1 ? [tw`text-white mr-3 rotate-180 xl:rotate-90 order-2 xl:order-1`] : [tw`hidden`]} />
              <h6 css={[tw`text-white px-2 xl:px-0 order-1 lg:text-4xl xl:order-2`]}>Find</h6>
            </div>
            <div css={[tw`flex flex-col xl:flex-row items-center content-center justify-end hover:scale-105 cursor-pointer`]} onClick={() => setActiveTab(2)}>

            <UpArrow css={activeTab == 2 ? [tw`text-white mr-3 rotate-180 xl:rotate-90  order-2 xl:order-1`] : [tw`hidden`]} />
              <h6 css={[tw`text-white px-2 xl:px-0 order-1 lg:text-4xl xl:order-2`]}>Contact</h6>
            </div>
          </div> */}
          </div>
          <div css={[tw`col-span-4 lg:col-span-6 flex flex-col`]}>
            <div
              css={[
                tw`bg-blue-300 grid grid-cols-1 lg:grid-cols-2 my-5 bg-opacity-70 h-[26rem]`,
              ]}
            >
              <div
                css={[
                  tw`col-span-1 flex content-center items-center lg:mx-5 py-6 pl-8 pr-8 lg:py-20  lg:pl-20 lg:pr-4 `,
                ]}
              >
                <div css={[activeTab == 1 ? [tw`text-white `] : [tw`hidden`]]}>
                  <h6 css={[tw`text-white text-2xl lg:text-4xl pb-3 `]}>
                    {data.find[0].headline}
                  </h6>
                  <p
                    dangerouslySetInnerHTML={{ __html: data.find[1].copy }}
                  ></p>
                </div>
                {/* <div css={[activeTab ==2 ? [tw`text-white`]: [tw`hidden`]]}>
                <h6 css={[tw`text-white text-2xl lg:text-4xl pb-3 `]}>{data.contact[0].headline}</h6>
                  <p dangerouslySetInnerHTML={{__html: data.contact[1].copy}}></p>
                </div> */}
              </div>

              <div css={[tw`hidden col-span-1 lg:flex justify-end relative `]}>
                <img
                  src={Robotics}
                  alt="Technicians operating a robot."
                  css={[tw`block h-56 w-96 object-cover absolute z-30 -top-8`]}
                />
                <img
                  src={Technicians}
                  alt="Technicians at work."
                  css={[
                    tw`block h-64 w-96 object-cover absolute z-30 -bottom-8 -right-6`,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile screen */}

      <section css={[tw`visible lg:hidden`]}>
        <div css={[tw`mx-auto container grid grid-cols-1 lg:grid-cols-2  `]}>
          <div css={[tw`col-span-1 mx-auto`]}>
            <div css={[tw`bg-red-300 mx-5 mt-[-32em]  p-8 md:p-20 relative`]}>
              <p
                css={[tw`text-white`]}
                dangerouslySetInnerHTML={{ __html: data.copy }}
              ></p>
            </div>
            <div
              css={[
                tw`w-48 left-0 right-0 bottom-[-33px] mx-auto border-t-red-300 border-t-[35px] border-r-transparent border-r-[120px] border-l-transparent border-l-[120px]`,
              ]}
            ></div>
          </div>
          <div css={[tw`col-span-1 h-full`]}>
            <div
              css={[
                tw`relative h-full flex justify-center lg:justify-start content-end items-end`,
              ]}
            >
              <img
                src={MapAL}
                alt="map of Alabama"
                css={[tw`h-36 lg:h-auto mt-[-2rem] lg:mt-0 lg:ml-[-4rem] `]}
              />
            </div>
          </div>
        </div>
        <div
          css={[
            tw`mx-auto grid grid-cols-1  mt-[-12em] pt-60 content-center items-center justify-center bg-cover bg-no-repeat bg-top bg-center`,
            `background-image: url(${Mobile_BG_outdoors})`,
            `background-size: 100% auto`,
          ]}
        >
          <div css={[tw` col-span-1 xl:col-span-2 px-5`]}>
            {/* <div css={[tw`flex items-start xl:items-end justify-center lg:justify-start xl:justify-center xl:flex-col my-3`]}>
            <div css={[tw`flex flex-col xl:flex-row items-center content-center justify-center xl:justify-end xl:pb-10 hover:scale-105 cursor-pointer`]} onClick={() => setActiveTab(1)}>
              <UpArrow css={activeTab == 1 ? [tw`text-white mr-3 rotate-180 xl:rotate-90 order-2 xl:order-1`] : [tw`hidden`]} />
              <h6 css={[tw`text-white px-2 xl:px-0 order-1 lg:text-4xl xl:order-2`]}>Find</h6>
            </div>
            <div css={[tw`flex flex-col xl:flex-row items-center content-center justify-end hover:scale-105 cursor-pointer`]} onClick={() => setActiveTab(2)}>
              <UpArrow css={activeTab == 2 ? [tw`text-white mr-3 rotate-180 xl:rotate-90  order-2 xl:order-1`] : [tw`hidden`]} />
              <h6 css={[tw`text-white px-2 xl:px-0 order-1 lg:text-4xl xl:order-2`]}>Contact</h6>
            </div>
          </div> */}
          </div>
          <div css={[tw`col-span-4 lg:col-span-6 flex flex-col px-5`]}>
            <div
              css={[
                tw`bg-blue-300 grid grid-cols-1 lg:grid-cols-2 my-5 bg-opacity-70 h-auto`,
              ]}
            >
              <div
                css={[
                  tw`col-span-1 flex content-center items-center lg:mx-5 py-6 md:px-20 pl-8 pr-8 lg:py-20  lg:pl-20 lg:pr-4 `,
                ]}
              >
                <div css={[activeTab == 1 ? [tw`text-white `] : [tw`hidden`]]}>
                  <h6 css={[tw`text-white text-2xl lg:text-4xl pb-3 `]}>
                    {data.find[0].headline}
                  </h6>
                  <p
                    dangerouslySetInnerHTML={{ __html: data.find[1].copy }}
                  ></p>
                </div>
                {/* <div css={[activeTab ==2 ? [tw`text-white`]: [tw`hidden`]]}>
                <h6 css={[tw`text-white text-2xl lg:text-4xl pb-3 `]}>{data.contact[0].headline}</h6>
                  <p dangerouslySetInnerHTML={{__html: data.contact[1].copy}}></p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div css={[tw`-mt-8 bg-black pb-3 lg:hidden `]}>
        <div
          css={[tw`lg:hidden flex flex-col items-center relative mx-auto px-5`]}
        >
          <img
            src={Robotics}
            alt="Gear up your career"
            css={[tw`block h-36 w-60 object-cover -ml-8`]}
          />
          <img
            src={Technicians}
            alt="Kiosks in mobile expo trailer"
            css={[tw`block h-36 w-60 object-cover -mr-8`]}
          />
        </div>
      </div>

      {/* start journey section */}

      {isAuthenticated && (
        <>
          <section css={[tw`bg-red-300 text-white`]}>
            <div
              css={[
                tw` mx-auto grid grid-cols-1 lg:grid-cols-3 content-center lg:h-72`,
              ]}
            >
              <div
                css={[
                  tw`col-span-2 mx-auto mt-16 mb-16 md:mb-0 pb-2 pt-8 md:mt-5 lg:mt-0 md:py-8 px-10 lg:px-10 2xl:ml-40 h-36 md:h-72 flex justify-center content-center items-center`,
                ]}
              >
                <div css={[tw`flex-col content-center items-center`]}>
                  {/* <p css={[tw`text-gray-700`]}>{data.section[0].copy}</p> */}
                  <p
                    css={[tw`text-white`]}
                    dangerouslySetInnerHTML={{ __html: data.section[0].copy }}
                  ></p>
                  <Link to="/check-registration">
                    <button
                      className="lg-button-blue"
                      type="button"
                      value="sign-up"
                    >
                      {data.section[1].button}
                    </button>
                  </Link>
                  <p css={[tw`pt-2 lg:pt-0 pb-2 text-white`]}></p>
                </div>
              </div>
              <div css={[tw`col-span-1 h-28 lg:h-72 w-full`]}>
                <div css={[tw`grid grid-cols-8 h-28 lg:h-72`]}>
                  <div
                    css={[
                      tw`col-span-2 lg:col-span-3 p-3 bg-blue-300 flex justify-center items-center`,
                    ]}
                  >
                    <UpArrow
                      css={[
                        tw`rotate-90 text-red-300 h-12 w-12 lg:h-24 lg:w-24`,
                      ]}
                    />
                  </div>
                  <div
                    css={[
                      tw`col-span-2 lg:col-span-3 p-3 bg-orange-300 flex justify-center items-center`,
                    ]}
                  >
                    <img
                      src={Slash}
                      alt="decorative slash"
                      css={[tw` h-12 w-12 lg:h-24 lg:w-24`]}
                    />
                  </div>
                  <div
                    css={[
                      tw`col-span-4 lg:col-span-2 bg-gray-100 w-full bg-cover bg-center`,
                      `background-image: url(${Stripes})`,
                    ]}
                  ></div>
                </div>
              </div>
            </div>
          </section>
          <section
            css={[tw`bg-red-300 lg:bg-gray-100 lg:text-gray-700 px-5 lg:px-0`]}
          >
            <div
              css={[
                tw`grid lg:grid-cols-3 lg:h-96 content-center justify-center`,
              ]}
            >
              <div css={[tw`col-span-1 lg:col-start-2 py-8 lg:py-16 lg:px-4`]}>
                <h6 css={[tw`text-white lg:text-gray-700 text-center pb-5`]}>
                  {data.resources[0].headline}
                </h6>
                <p css={[tw`text-white lg:text-gray-700 pb-8 px-5 lg:px-10`]}>
                  {data.resources[1].copy}
                </p>
                <p
                  css={[
                    tw`text-white lg:text-gray-700 text-center text-xl font-bold`,
                  ]}
                >
                  <span css={[tw`underline`]}>
                    <Link to="/career-resources">Career</Link>
                  </span>{" "}
                  |{" "}
                  <span css={[tw`underline`]}>
                    <Link to="/education-resources">Education</Link>
                  </span>{" "}
                  |{" "}
                  <span css={[tw`underline`]}>
                    <Link to="/lifestyle-resources">Lifestyle</Link>
                  </span>
                </p>
              </div>
            </div>
          </section>
        </>
      )}

      {!isAuthenticated && (
        <>
          <section css={[tw`bg-red-300 text-white`]}>
            <div
              css={[
                tw` mx-auto grid grid-cols-1 lg:grid-cols-3 content-center lg:h-72`,
              ]}
            >
              <div
                css={[
                  tw`col-span-2 mx-auto mt-48 pb-2 pt-8 md:mt-5 lg:mt-0 md:py-8 px-10 lg:px-10 2xl:ml-40 h-36 md:h-72 flex justify-center content-center items-center`,
                ]}
              >
                <div css={[tw`flex-col content-center items-center`]}>
                  {/* <p css={[tw`text-gray-700`]}>{data.section[0].copy}</p> */}
                  <p
                    css={[tw`text-white`]}
                    dangerouslySetInnerHTML={{ __html: data.section[0].copy }}
                  ></p>
                  <Link to="/check-registration">
                    <button
                      className="lg-button-blue"
                      type="button"
                      value="sign-up"
                    >
                      {data.section[1].button}
                    </button>
                  </Link>
                  <div css={["content-center items-center"]}>
                    <p css={[tw`pt-32 lg:pt-0 pb-8 text-white`]}>
                      {data.CTA_text}{" "}
                      <Link to="/check-registration">
                        <span css={[tw`underline cursor-pointer`]}>
                          {data.CTA}
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div css={[tw`col-span-1 h-28 lg:h-72 w-full`]}>
                <div css={[tw`grid grid-cols-8 h-28 lg:h-72`]}>
                  <div
                    css={[
                      tw`col-span-2 lg:col-span-3 p-3 bg-blue-300 flex justify-center items-center`,
                    ]}
                  >
                    <UpArrow
                      css={[
                        tw`rotate-90 text-red-300 h-12 w-12 lg:h-24 lg:w-24`,
                      ]}
                    />
                  </div>
                  <div
                    css={[
                      tw`col-span-2 lg:col-span-3 p-3 bg-orange-300 flex justify-center items-center`,
                    ]}
                  >
                    <img
                      src={Slash}
                      alt="decorative slash"
                      css={[tw` h-12 w-12 lg:h-24 lg:w-24`]}
                    />
                  </div>
                  <div
                    css={[
                      tw`col-span-4 lg:col-span-2 bg-gray-100 w-full bg-cover bg-center`,
                      `background-image: url(${Stripes})`,
                    ]}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section css={[tw`grid grid-cols-2`]}>
        <div css={[tw`col-span-1 h-36 md:h-72 lg:h-[43em]`]}>
          <img
            src={Lifestyle}
            alt="Mom and son on a sunny sidewalk."
            css={[
              tw`object-cover object-top w-full  h-36  md:h-72 lg:h-[43em]`,
            ]}
          />
        </div>
        <div css={[tw`col-span-1 h-36  md:h-72 lg:h-[43em]`]}>
          <img
            src={AlexT}
            alt="Person wearing alex t-shirt."
            css={[tw`object-cover object-top w-full h-36  md:h-72 lg:h-[43em]`]}
          />
        </div>
      </section>
    </>
  )
}

export default Home
